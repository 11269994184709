import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Box, Link } from '@mui/material';
import PlanCarousel from '../components/WorkoutPlan/PlanCarousel';
import PointsCard from '../components/HomeScreenComponents/PointsCard';
import languages from '../language/languages';
import db, { preloadImages, Database } from '../database/db';
import ReservationsCarousel from '../components/HomeScreenComponents/ReservationsCarousel';
import userService from '../services/UserServices';
import { userActions } from '../redux/slices/userSlice';

declare const progressier: any;

const Home: FC = () => {
  const { email, phone, sex, business_id, last_name, first_name, offline } = useSelector(
    (state: RootState) => state.user,
  );
  const { selected } = useSelector((state: RootState) => state.language);
  const { token, id } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const imagesPreloaded = useRef(false);
  useEffect(() => {
    const handleLoad = () => {
      progressier.add({
        email: email,
        phone: phone,
        name: `${first_name} ${last_name}`,
        tags: `${sex}, ${business_id}`,
      });
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (offline && !imagesPreloaded.current) {
      preloadImages();
      imagesPreloaded.current = true;
    } else if (!offline) {
      (db as Database).imagenes.clear();
    }
  }, [offline]);

  const redirection = async (token: string) => {
    const response = await userService.getRedirect(token);
    if (response) {
      const { redirect } = response;
      console.log(redirect, id);
      dispatch(userActions.redirect({ redirect }));
    } else {
      dispatch(userActions.redirect({ redirect: false }));
    }
  };

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (token) {
      redirection(token);
    }
  }, []);

  return (
    <Box sx={{ paddingTop: '10vh' }}>
      {business_id === 1 ? (
        <ReservationsCarousel />
      ) : (
        <>
          <Box sx={{ textAlign: 'left', marginX: '5vw', marginY: '0', paddingY: '0' }}>
            {first_name && (
              <h2 className="App-logo-text">
                {languages[selected].home.welcome(first_name)} {sex === 'F' ? `👩‍🚀` : `👨‍🚀`}
              </h2>
            )}
          </Box>
          <PointsCard />
        </>
      )}
      <PlanCarousel />
      <Box
        sx={{
          borderRadius: '20px',
          backgroundImage: 'url(https://cdn.makana.cl/mkn-591.jpg)',
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100% auto`,
          backgroundPosition: 'center',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          minWidth: {
            xs: '90vw', // Dispositivos móviles
            sm: '400px', // Otros tamaños de pantalla
          },
          minHeight: '20vh',
          marginX: '5vw',
          marginTop: 3,
          marginBottom: 15,
          paddingBottom: '0vh',
        }}>
        <Box
          sx={{
            borderRadius: '20px',
            backgroundImage: 'linear-gradient(to bottom right,#432680a0, #0068ffc0)',
            paddingBottom: '4vh',
            paddingTop: '0.5vh',
            width: '100%',
            height: '100%',
            minWidth: {
              xs: '90vw', // Dispositivos móviles
              sm: '400px', // Otros tamaños de pantalla
            },
            minHeight: '20vh',
            margin: '0vh',
          }}>
          <Box sx={{ marginX: '2vw', marginY: '5vw', paddingY: '0', fontSize: { sm: 24 }, lineHeight: { sm: 2.3 } }}>
            <h1 className="App-profile-text">{languages[selected].home.recommender.title}</h1>
          </Box>
          <Link
            href="/charlie"
            sx={{
              color: 'primary.main',
              backgroundColor: '#FFFFFFc0',
              width: '100%',
              borderRadius: '50px',
              fontWeight: 'bold',
              boxShadow: 'none',
              paddingY: '2vh',
              paddingX: '5vw',
              textDecoration: 'none',
            }}>
            {languages[selected].home.recommender.button}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
